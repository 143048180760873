var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.loadingWorkDocx)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_c('v-container',[_c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',[_vm._v("Готовность заключения (Представления о ЛП)")]),(_vm.loadingWorkDocx)?_c('v-card-text',[_c('v-skeleton-loader',{attrs:{"type":"list-item-two-line, list-item-two-line, list-item-two-line"}})],1):_c('v-card-text',[_c('v-expansion-panels',{staticClass:"my-2",attrs:{"flat":""}},[_c('v-expansion-panel',{attrs:{"readonly":!_vm.checkPresentation}},[_c('v-expansion-panel-header',{staticClass:"py-0",style:(!_vm.checkPresentation ? 'cursor: default' : 'cursor: pointer'),attrs:{"color":_vm.checkPresentation ? '#ecf4eb' : '#fdf2e7',"expand-icon":_vm.checkPresentation ? 'mdi-chevron-down' : ''},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.isManager && !_vm.checkPresentation)?_c('LastActionDialog',{attrs:{"value":_vm.objSummary},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var value = ref.value;
return [_c('SummaryForLog',{attrs:{"value":value,"readonly":""}})]}}],null,false,4055395343)}):_vm._e()]},proxy:true}])},[_c('v-row',{staticClass:"exp_header_text ml-8",style:(_vm.checkPresentation ? 'color: #5d9e52' : 'color: #ec9235')},[_c('v-icon',{staticClass:"exp_icon",style:(_vm.checkPresentation ? 'color: #5d9e52' : 'color: #ec9235')},[_vm._v("mdi-numeric-1")]),_c('span',[_vm._v(_vm._s(_vm.getPresentation))])],1)],1),_c('v-expansion-panel-content',{attrs:{"eager":""}},[(_vm.checkPresentation)?_c('SummaryForLog',{attrs:{"value":_vm.expertise.summary}}):_vm._e()],1)],1)],1),_c('v-expansion-panels',{staticClass:"my-2",attrs:{"flat":""}},[_c('v-expansion-panel',{attrs:{"readonly":!_vm.checkRefPricing}},[_c('v-expansion-panel-header',{staticClass:"py-0",style:(!_vm.checkRefPricing ? 'cursor: default' : 'cursor: pointer'),attrs:{"color":_vm.checkRefPricing ? '#ecf4eb' : '#fdf2e7',"expand-icon":_vm.checkRefPricing ? 'mdi-chevron-down' : 'mdi-eye'},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.isManager && !_vm.checkRefPricing)?_c('LastActionDialog',{attrs:{"value":_vm.objRef},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var value = ref.value;
return [_c('RefPricing',{attrs:{"value":value,"readonly":""}})]}}],null,false,3143880229)}):_vm._e()]},proxy:true}])},[_c('v-row',{staticClass:"exp_header_text ml-8 mr-10",style:(_vm.checkRefPricing ? 'color: #5d9e52' : 'color: #ec9235')},[_c('v-icon',{staticClass:"exp_icon",style:(_vm.checkRefPricing ? 'color: #5d9e52' : 'color: #ec9235')},[_vm._v("mdi-numeric-2")]),_c('span',[_vm._v(_vm._s(_vm.getPricing))])],1)],1),_c('v-expansion-panel-content',{attrs:{"eager":""}},[(_vm.checkRefPricing)?_c('RefPricing',{attrs:{"value":_vm.expertise.ref,"readonly":""}}):_vm._e()],1)],1)],1),_c('v-expansion-panels',{staticClass:"my-2",attrs:{"flat":""}},[_c('v-expansion-panel',{attrs:{"readonly":!_vm.checkCost}},[_c('v-expansion-panel-header',{staticClass:"py-0",style:(!_vm.checkCost ? 'cursor: default' : 'cursor: pointer'),attrs:{"color":_vm.checkCost ? '#ecf4eb' : '#fdf2e7',"expand-icon":_vm.checkCost ? 'mdi-chevron-down' : ''},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.isManager && !_vm.checkCost)?_c('LastActionDialog',{attrs:{"value":_vm.objCost},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var value = ref.value;
return [_c('CostCalc',{attrs:{"value":value,"readonly":""}})]}}],null,false,1161690970)}):_vm._e()]},proxy:true}])},[_c('v-row',{staticClass:"exp_header_text ml-8",style:(_vm.checkCost ? 'color: #5d9e52' : 'color: #ec9235')},[_c('v-icon',{staticClass:"exp_icon",style:(_vm.checkCost ? 'color: #5d9e52' : 'color: #ec9235')},[_vm._v("mdi-numeric-3")]),_c('span',[_vm._v(_vm._s(_vm.getCost))])],1)],1),_c('v-expansion-panel-content',{attrs:{"eager":""}},[(_vm.checkCost)?_c('CostCalc',{attrs:{"value":_vm.expertise.cost,"log-view":""}}):_vm._e()],1)],1)],1),_c('v-expansion-panels',{staticClass:"my-2",attrs:{"flat":""}},[_c('v-expansion-panel',{attrs:{"readonly":!_vm.checkAgencies}},[_c('v-expansion-panel-header',{staticClass:"py-0",style:(!_vm.checkAgencies ? 'cursor: default' : 'cursor: pointer'),attrs:{"color":_vm.checkAgencies ? '#ecf4eb' : '#fdf2e7',"expand-icon":_vm.checkAgencies ? 'mdi-chevron-down' : ''},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.isManager && !_vm.checkAgencies)?_c('LastActionDialog',{attrs:{"value":_vm.objAgencies},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var value = ref.value;
return [_c('ExpAgency',{attrs:{"value":value,"readonly":""}})]}}],null,false,3543855398)}):_vm._e()]},proxy:true}])},[_c('v-row',{staticClass:"exp_header_text ml-8",style:(_vm.checkAgencies ? 'color: #5d9e52' : 'color: #ec9235')},[_c('v-icon',{staticClass:"exp_icon",style:(_vm.checkAgencies ? 'color: #5d9e52' : 'color: #ec9235')},[_vm._v("mdi-numeric-4")]),_c('span',[_vm._v(_vm._s(_vm.getAgencies))])],1)],1),_c('v-expansion-panel-content',{attrs:{"eager":""}},[(_vm.checkAgencies)?_c('ExpAgency',{attrs:{"value":_vm.expertise.agencies,"readonly":""}}):_vm._e()],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.downloadDocx,"disabled":!_vm.getProperties},on:{"click":_vm.exportDocx}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-download ")]),_vm._v(" Выгрузить представление о ЛП (.docx) ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }