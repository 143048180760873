<template>
  <v-container fluid>
    <v-progress-linear v-if="loadingWorkDocx" indeterminate />
    <v-container>
      <v-card class="elevation-0">
        <v-card-title>Готовность заключения (Представления о ЛП)</v-card-title>
        <v-card-text v-if="loadingWorkDocx">
          <v-skeleton-loader
            type="list-item-two-line, list-item-two-line, list-item-two-line"
          />
        </v-card-text>
        <v-card-text v-else>
          <v-expansion-panels class="my-2" flat>
            <v-expansion-panel :readonly="!checkPresentation">
              <v-expansion-panel-header
                :style="
                  !checkPresentation ? 'cursor: default' : 'cursor: pointer'
                "
                :color="checkPresentation ? '#ecf4eb' : '#fdf2e7'"
                :expand-icon="checkPresentation ? 'mdi-chevron-down' : ''"
                class="py-0"
              >
                <template #actions
                  ><LastActionDialog
                    v-if="isManager && !checkPresentation"
                    :value="objSummary"
                  >
                    <template #content="{ value }">
                      <SummaryForLog :value="value" readonly />
                    </template> </LastActionDialog
                ></template>
                <v-row
                  :style="
                    checkPresentation ? 'color: #5d9e52' : 'color: #ec9235'
                  "
                  class="exp_header_text ml-8"
                  ><v-icon
                    :style="
                      checkPresentation ? 'color: #5d9e52' : 'color: #ec9235'
                    "
                    class="exp_icon"
                    >mdi-numeric-1</v-icon
                  >
                  <span>{{ getPresentation }}</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <SummaryForLog
                  v-if="checkPresentation"
                  :value="expertise.summary"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-expansion-panels class="my-2" flat>
            <v-expansion-panel :readonly="!checkRefPricing">
              <v-expansion-panel-header
                :style="
                  !checkRefPricing ? 'cursor: default' : 'cursor: pointer'
                "
                :color="checkRefPricing ? '#ecf4eb' : '#fdf2e7'"
                :expand-icon="checkRefPricing ? 'mdi-chevron-down' : 'mdi-eye'"
                class="py-0"
              >
                <template #actions>
                  <LastActionDialog
                    v-if="isManager && !checkRefPricing"
                    :value="objRef"
                  >
                    <template #content="{ value }">
                      <RefPricing :value="value" readonly />
                    </template>
                  </LastActionDialog>
                </template>
                <v-row
                  :style="checkRefPricing ? 'color: #5d9e52' : 'color: #ec9235'"
                  class="exp_header_text ml-8 mr-10"
                  ><v-icon
                    :style="
                      checkRefPricing ? 'color: #5d9e52' : 'color: #ec9235'
                    "
                    class="exp_icon"
                    >mdi-numeric-2</v-icon
                  >
                  <span>{{ getPricing }}</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <RefPricing
                  v-if="checkRefPricing"
                  :value="expertise.ref"
                  readonly
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-expansion-panels class="my-2" flat>
            <v-expansion-panel :readonly="!checkCost">
              <v-expansion-panel-header
                :style="!checkCost ? 'cursor: default' : 'cursor: pointer'"
                :color="checkCost ? '#ecf4eb' : '#fdf2e7'"
                :expand-icon="checkCost ? 'mdi-chevron-down' : ''"
                class="py-0"
              >
                <template #actions
                  ><LastActionDialog
                    v-if="isManager && !checkCost"
                    :value="objCost"
                  >
                    <template #content="{ value }">
                      <CostCalc :value="value" readonly />
                    </template> </LastActionDialog
                ></template>
                <v-row
                  :style="checkCost ? 'color: #5d9e52' : 'color: #ec9235'"
                  class="exp_header_text ml-8"
                  ><v-icon
                    :style="checkCost ? 'color: #5d9e52' : 'color: #ec9235'"
                    class="exp_icon"
                    >mdi-numeric-3</v-icon
                  >
                  <span>{{ getCost }}</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <CostCalc v-if="checkCost" :value="expertise.cost" log-view />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-expansion-panels class="my-2" flat>
            <v-expansion-panel :readonly="!checkAgencies">
              <v-expansion-panel-header
                :style="!checkAgencies ? 'cursor: default' : 'cursor: pointer'"
                :color="checkAgencies ? '#ecf4eb' : '#fdf2e7'"
                :expand-icon="checkAgencies ? 'mdi-chevron-down' : ''"
                class="py-0"
              >
                <template #actions
                  ><LastActionDialog
                    v-if="isManager && !checkAgencies"
                    :value="objAgencies"
                  >
                    <template #content="{ value }">
                      <ExpAgency :value="value" readonly />
                    </template> </LastActionDialog
                ></template>
                <v-row
                  :style="checkAgencies ? 'color: #5d9e52' : 'color: #ec9235'"
                  class="exp_header_text ml-8"
                  ><v-icon
                    :style="checkAgencies ? 'color: #5d9e52' : 'color: #ec9235'"
                    class="exp_icon"
                    >mdi-numeric-4</v-icon
                  >
                  <span>{{ getAgencies }}</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <ExpAgency
                  v-if="checkAgencies"
                  :value="expertise.agencies"
                  readonly
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider />
          <v-card-actions class="text-right">
            <v-btn
              color="primary"
              :loading="downloadDocx"
              :disabled="!getProperties"
              @click="exportDocx"
              ><v-icon left> mdi-download </v-icon>
              Выгрузить представление о ЛП (.docx)
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import {
  Document,
  Packer,
  Paragraph,
  AlignmentType,
  PageOrientation,
  convertMillimetersToTwip,
  NumberFormat,
  TextRun,
  PageNumber,
  Footer,
} from 'docx'
import { saveAs } from 'file-saver'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import { createOneChapter } from '@/components/reportPresentation/allChapter/oneChapter'
import { createTwoChapter } from '@/components/reportPresentation/allChapter/twoChapter'
import {
  SUB_EX_ABOUT,
  SUB_EXPERTISE_FOR_SUMMARY_REPORT,
} from '@/store/const/expertise'
import SummaryForLog from '@/components/expSummary/SummaryForLog'
import RefPricing from '@/components/expPricing/RefPricing'
import LastActionDialog from '@/components/viewLogs/LastActionDialog'
import CostCalc from '@/components/expCost/CostCalc'
import ExpAgency from '../expAgency/ExpAgency.vue'
import { createThreeChapter } from '@/components/reportPresentation/allChapter/threeChapter'

export default {
  name: 'PresentationDocx',
  components: {
    CostCalc,
    LastActionDialog,
    RefPricing,
    ExpAgency,
    SummaryForLog,
  },
  data: () => ({
    loadingWorkDocx: false,
    downloadDocx: false,
    objSummary: null,
    objRef: null,
    objCost: null,
    objAgencies: null,
    expertise: null,
    itemsMNN: [],
    analogMap: {},
  }),
  computed: {
    ...mapGetters([
      'storedPlpSigner',
      'storedPlp',
      'storedPlpId',
      'storedPlpMNN',
      'isManager',
      'plp_task_id',
    ]),

    mnn() {
      // из экспертизы или уже как есть в PLP - как резерв
      return this.expertise?.summary?.mnn || this.storedPlpMNN
    },

    getPresentation() {
      return this.checkPresentation
        ? 'Представление о ЛП готово'
        : 'Преставление о ЛП не готово!'
    },
    getPricing() {
      return this.checkRefPricing
        ? 'Информация о референтных странах готова'
        : 'Информация о референтных странах не готова!'
    },
    getCost() {
      return this.checkCost
        ? 'Информация о "Расчёт затрат" готова'
        : 'Информация о "Расчёт затрат" не готова!'
    },
    getAgencies() {
      return this.checkAgencies
        ? 'Информация о зарубежных агенств готова'
        : 'Информация о зарубежных агенств не готова!'
    },
    checkPresentation() {
      return this.expertise?.summary
    },
    checkRefPricing() {
      return this.expertise?.ref
    },
    checkCost() {
      return this.expertise?.cost
    },
    checkAgencies() {
      return this.expertise?.agencies
    },
    checkFullExpertise() {
      return this.checkPresentation && this.checkRefPricing && this.checkCost
    },
    //Свойство, проверяющее каждое свойство отчета на его наличие
    getProperties() {
      return this.checkFullExpertise
    },
  },
  mounted() {
    this.loadReportData()
  },
  methods: {
    ...mapActions(['GET_PLP_REPORT', 'CREATE_ACTION_LOG']),
    ...mapMutations(['SET_ERROR']),

    async loadReportData() {
      this.loadingWorkDocx = true
      try {
        const plp_id = this.storedPlpId
        const { expertise } = await this.GET_PLP_REPORT([
          ...SUB_EXPERTISE_FOR_SUMMARY_REPORT,
          SUB_EX_ABOUT,
        ])

        this.expertise = expertise || {}
        if (!expertise.summary) {
          this.objSummary = {
            codes: ['PLP_SUBEX_VER_MODIFIED', 'PLP_SUBEX_VER_CREATED'],
            plp_id,
            ex_type_name: 'summary',
          }
        }
        if (!expertise.ref) {
          this.objRef = {
            codes: ['PLP_SUBEX_VER_MODIFIED', 'PLP_SUBEX_VER_CREATED'],
            plp_id,
            ex_type_name: 'ref',
          }
        }
        if (!expertise.agencies) {
          this.objAgencies = {
            codes: ['PLP_SUBEX_VER_MODIFIED', 'PLP_SUBEX_VER_CREATED'],
            plp_id,
            ex_type_name: 'agencies',
          }
        }
        if (!expertise.cost) {
          this.objCost = {
            codes: ['PLP_SUBEX_VER_MODIFIED', 'PLP_SUBEX_VER_CREATED'],
            plp_id,
            ex_type_name: 'cost',
          }
        }
      } catch (err) {
        this.SET_ERROR({
          head: 'Заключение',
          text: 'Ошибка загрузки данных документа.',
          err,
        })
      } finally {
        this.loadingWorkDocx = false
      }
    },
    getAlertType(value) {
      return value ? 'success' : 'warning'
    },
    getAlertIcon(value) {
      return value ? 'mdi-check' : undefined
    },
    beStrong(value) {
      return `<span class="font-weight-medium">${value ?? ''}</span>`
    },
    exportDocx() {
      this.downloadDocx = true
      try {
        const doc = new Document({
          sections: [
            {
              //Задаём нумерацию страниц
              footers: {
                default: new Footer({
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.RIGHT,
                      children: [
                        new TextRun({
                          children: [PageNumber.CURRENT],
                        }),
                      ],
                    }),
                  ],
                }),
              },
              properties: {
                page: {
                  size: {
                    //Задаём альбомную ориентацию документа, размеры листа A4
                    orientation: PageOrientation.PORTRAIT,
                    height: convertMillimetersToTwip(210),
                    width: convertMillimetersToTwip(297),
                  },
                  //отступы страницы, Величина измерения-пункты. Требуется подогнать ее под сантиметры
                  margin: {
                    top: convertMillimetersToTwip(20), //2 сантимерта
                    right: convertMillimetersToTwip(20), //2 сантиметра
                    bottom: convertMillimetersToTwip(15), //1.5 сантиметра
                    left: convertMillimetersToTwip(20), //2 сантиметра
                  },
                  pageNumbers: {
                    formatType: NumberFormat.DECIMAL,
                  },
                },
              },
              // весь документ
              children: [...createOneChapter(this.expertise?.summary)],
            },
            {
              //Задаём нумерацию страниц
              footers: {
                default: new Footer({
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.RIGHT,
                      children: [
                        new TextRun({
                          children: [PageNumber.CURRENT],
                        }),
                      ],
                    }),
                  ],
                }),
              },
              properties: {
                page: {
                  size: {
                    //Задаём альбомную ориентацию документа, размеры листа A4
                    orientation: PageOrientation.PORTRAIT,
                    height: convertMillimetersToTwip(210),
                    width: convertMillimetersToTwip(297),
                  },
                  //отступы страницы, Величина измерения-пункты. Требуется подогнать ее под сантиметры
                  margin: {
                    top: convertMillimetersToTwip(20), //2 сантимерта
                    right: convertMillimetersToTwip(20), //2 сантиметра
                    bottom: convertMillimetersToTwip(15), //1.5 сантиметра
                    left: convertMillimetersToTwip(20), //2 сантиметра
                  },
                  pageNumbers: {
                    formatType: NumberFormat.DECIMAL,
                  },
                },
              },
              // весь документ
              children: [
                ...createTwoChapter(
                  this.expertise?.ref,
                  this.expertise?.about?.mnn || '',
                  this.expertise?.about,
                  this.expertise?.agencies
                ),
              ],
            },
            {
              //Задаём нумерацию страниц
              footers: {
                default: new Footer({
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.RIGHT,
                      children: [
                        new TextRun({
                          children: [PageNumber.CURRENT],
                        }),
                      ],
                    }),
                  ],
                }),
              },
              properties: {
                page: {
                  size: {
                    //Задаём альбомную ориентацию документа, размеры листа A4
                    orientation: PageOrientation.PORTRAIT,
                    height: convertMillimetersToTwip(210),
                    width: convertMillimetersToTwip(297),
                  },
                  //отступы страницы, Величина измерения-пункты. Требуется подогнать ее под сантиметры
                  margin: {
                    top: convertMillimetersToTwip(20), //2 сантимерта
                    right: convertMillimetersToTwip(20), //2 сантиметра
                    bottom: convertMillimetersToTwip(15), //1.5 сантиметра
                    left: convertMillimetersToTwip(20), //2 сантиметра
                  },
                  pageNumbers: {
                    formatType: NumberFormat.DECIMAL,
                  },
                },
              },
              // весь документ
              children: [...createThreeChapter(this.expertise?.cost)],
            },
          ],
        })
        Packer.toBlob(doc).then(blob => {
          const plp_id = this.storedPlpId
          //ЗАПИСЫВАЕМ В ЛОГ ВЫГРУЗКУ ОТЧЁТА
          this.CREATE_ACTION_LOG({
            code: 'SUMMARY_REPORT_SAVE',
            message: `Сгенерировано заключение представления для "${this.mnn}"`,
            data: { plp_id },
          })
          saveAs(
            blob,
            `Заключение представления для ${this.mnn} от ${this.getTimestamp()}`
          )
        })

        return doc
      } catch (err) {
        this.SET_ERROR({
          head: 'Заключение',
          text: 'Ошибка формирования документа',
          err,
        })
        throw err
      } finally {
        this.downloadDocx = false
      }
    },
    getTimestamp() {
      const date = new Date()
      const addZero = v => (v.toString().length === 1 ? `0${v}` : v)
      const yyyy = date.getFullYear()
      const MM = addZero(date.getMonth() + 1)
      const dd = addZero(date.getDate())
      return `${dd}_${MM}_${yyyy}`
    },
  },
}
</script>

<style scoped>
.exp_icon {
  position: absolute;
  left: -40px;
  top: -5px;
  /*color: #5d9e52;*/
}
.exp_header_text {
  /*color: #5d9e52;*/
  position: absolute;
}
</style>
