import {
  DocxException,
  createParagraph,
  createParagraphWithCenter,
  createEmptyLine,
  createBoldParagraph,
  UNDERLINED_TEXT,
} from '../docxFunctions'
import { createPurchaseTable } from '@/components/reportPresentation/allTables/createPurchaseTable'
import { createCharacteristics } from '@/components/reportPresentation/allTables/createCharacteristicsTable'
import { createEffectsTable } from '@/components/reportPresentation/allTables/createCrEffectsTable'
import { createKeiTable } from '@/components/reportPresentation/allTables/createKeiTable'
import { createAvbTable } from '@/components/reportPresentation/allTables/createAvbTable'
import {
  BOLD_TEXT,
  createCombinationParagraph,
  createCombinationParagraphWithCenter,
  createCombinationParagraphWithSpace,
  createItalicParagraphWithSpace,
  DEFAULT_TEXT,
  ITALIC_BOLD_TEXT,
  ITALIC_TEXT,
} from '@/components/report/docxFunctions'

export function createOneChapter(summary) {
  try {
    //Перечень
    const listArr = []
    if (summary.gnvlp) listArr.push('ЖВНЛП')
    if (summary.vzn14) listArr.push('ВЗН14')
    const list = listArr.join(', ')

    const gvsList = []
    summary.gvs.forEach(item => {
      gvsList.push(
        createCombinationParagraph([
          {
            type: DEFAULT_TEXT,
            content: `Главный внештатный (${item.gvsSpec}) Минздрава России (${item.gvsFio})`,
          },
          {
            type: BOLD_TEXT,
            content: ` считает ${
              item.conclusionExpediency ? 'целесообразным' : 'нецелесообразным'
            } включения `,
          },
          {
            type: DEFAULT_TEXT,
            content: `препарата в перечень `,
          },
          {
            type: BOLD_TEXT,
            content: list,
          },
        ]),
        createCombinationParagraph([
          { type: ITALIC_TEXT, content: 'комментарий: ' },
        ]),
        createCombinationParagraph([
          { type: ITALIC_TEXT, content: item.comment },
        ]),
        createEmptyLine(),
        createCombinationParagraph([
          {
            type: DEFAULT_TEXT,
            content: 'По результатам рассмотрения лекарственный препарат ',
          },
          {
            type: UNDERLINED_TEXT,
            content: `${
              item.lpPoints === 'Да' ? 'набрал' : 'не набрал'
            } рекомендованное количество баллов `,
          },
          {
            type: DEFAULT_TEXT,
            content: `по шкалам (${item.notDidPoints || ''})`,
          },
        ]),
        // createCombinationParagraph([{type: DEFAULT_TEXT}]),
        createEmptyLine()
      )
    })
    return [
      createCombinationParagraphWithCenter([
        {
          type: BOLD_TEXT,
          content: summary.mnn || 'МНН',
          size: 32,
        },
        {
          type: ITALIC_BOLD_TEXT,
          content: ` (вкл. в ${list})`,
          size: 32,
        },
      ]),
      createCombinationParagraph([
        {
          type: BOLD_TEXT,
          content: 'Лекарственная форма: ',
        },
        {
          type: DEFAULT_TEXT,
          content: summary.lForma,
        },
      ]),
      createCombinationParagraph([
        {
          type: BOLD_TEXT,
          content: 'Дата регистрации ЛП: ',
        },
        {
          type: DEFAULT_TEXT,
          content: summary.ruDate,
        },
      ]),
      createCombinationParagraph([
        {
          type: BOLD_TEXT,
          content: 'Орфанный статус: ',
        },
        {
          type: DEFAULT_TEXT,
          content: summary.isOrphan ? 'Да.' : 'Нет.',
        },
      ]),
      createCombinationParagraph([
        {
          type: BOLD_TEXT,
          content: 'Наличие ЛП в клинических рекомендациях: ',
        },
        {
          type: DEFAULT_TEXT,
          content: summary.crNames.length ? summary.crNames : 'Нет.',
        },
      ]),
      createBoldParagraph(
        'Заявленные показания к применению лекарственного препарата:'
      ),
      createParagraph(summary.condition),
      createCombinationParagraph([
        {
          type: BOLD_TEXT,
          content:
            'Наличие в перечне критической промышленной продукции в отрасли фарм. промышленности: ',
        },
        {
          type: DEFAULT_TEXT,
          content: 'нет',
        },
      ]),
      createCombinationParagraph([
        {
          type: BOLD_TEXT,
          content: 'Данные по закупке ',
        },
        {
          type: DEFAULT_TEXT,
          content: summary.purchaseData?.year
            ? `(за ${summary.purchaseData.year} г.): `
            : '',
        },
        {
          type: BOLD_TEXT,
          content: `${
            summary.purchaseData?.subjects?.split(',').length
          } субъекта Российской федерации`,
        },
      ]),
      createPurchaseTable(summary.purchaseData?.releaseForms),
      createCombinationParagraph([
        {
          type: BOLD_TEXT,
          content: 'Ценовая характеристика ЛП',
        },
      ]),
      createCharacteristics(summary.priceCharacteristics),
      createCombinationParagraph([
        {
          type: BOLD_TEXT,
          content: 'Предложение направлено на комплексную оценку в: ',
        },
        {
          type: DEFAULT_TEXT,
          content: summary.assessmentOrganization?.nameExpOrg,
        },
      ]),
      createEmptyLine(),
      createCombinationParagraph([
        {
          type: DEFAULT_TEXT,
          content: 'По результатам проведенной оценки сделан вывод о ',
        },
        {
          type: BOLD_TEXT,
          content: summary.assessmentOrganization?.conclusionExpediency
            ? 'целесообразности включения'
            : 'нецелесообразности включения',
        },
        {
          type: DEFAULT_TEXT,
          content: ` препарата в перечень ${list}`,
        },
      ]),
      createCombinationParagraphWithSpace([
        { type: DEFAULT_TEXT, content: 'Комментарии: ' },
      ]),
      createItalicParagraphWithSpace(summary.assessmentOrganization?.comment),
      ...gvsList,
      createParagraph(
        'Методологическое качество исследований озвучит представитель ФГБУ «Центр экспертизы и контроля качества медицинской помощи».'
      ),
      createParagraph(
        'Позиция органов государственной власти субъектов РФ в сфере охраны здоровья (регионов):'
      ),
      createParagraph(
        '-  Х субъектов РФ полагают целесообразным включение ЛП в перечень ЖНВЛП, при этом Х из них отмечает увеличение бюджета;'
      ),
      createParagraph(
        '-  Х субъектов РФ считают нецелесообразным включение ЛП в перечень ЖНВЛП ввиду отсутствия практики применения;'
      ),
      createParagraph(
        '-  Х субъектов РФ полагает возможным включение ЛП в перечень ЖНВЛП при условии выделения дополнительных средств;'
      ),
      createParagraph(
        '- Х субъектов РФ отмечает отсутствие пациентов и (или) опыта применения.'
      ),
      createParagraphWithCenter(
        `Дополнительная информация`,
        {
          size: 28,
          bold: true,
        },
        null
      ),
      createParagraphWithCenter(
        `Данные о сравнительной клинической эффективности`,
        {
          size: 28,
          bold: true,
          underline: true,
        },
        null
      ),
      createEffectsTable(summary.additionalInfo?.crEffects),
      createEmptyLine(),
      createParagraphWithCenter(
        `Данные о клинико-экономической эффективности `,
        {
          size: 28,
          bold: true,
        },
        null
      ),
      summary.additionalInfo?.kei?.length
        ? createKeiTable(summary.additionalInfo?.kei)
        : createItalicParagraphWithSpace(`Нет данных`),
      ,
      createEmptyLine(),
      createParagraphWithCenter(
        `Данные о влиянии на бюджет`,
        {
          size: 28,
          bold: true,
        },
        null
      ),
      summary.additionalInfo?.avb?.length
        ? createAvbTable(summary.additionalInfo?.avb)
        : createItalicParagraphWithSpace(`Нет данных`),
    ]
  } catch (err) {
    throw new DocxException(1, err.message)
  }
}
