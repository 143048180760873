import {
  createParagraphWithCenter,
  DocxException,
  createEmptyLine,
} from '../docxFunctions'
import { createRefTable } from '@/components/reportPresentation/allTables/createRefTable'
import { createAgenciesTable } from '../allTables/createAgenciesTable'

export function createTwoChapter(reference, mnn, about, agencies) {
  try {
    return [
      createParagraphWithCenter(
        `Сравнение предлагаемой к регистрации цены в РФ и`,
        {
          size: 28,
          bold: true,
        },
        null
      ),
      createParagraphWithCenter(
        `зарегистрированной минимальной цены в референтных странах`,
        {
          size: 28,
          bold: true,
        },
        null
      ),
      createRefTable(reference.pricing, mnn),
      createEmptyLine(),
      createParagraphWithCenter(
        `Сведения о рекомендациях зарубежных агентств по оценке медицинских `,
        {
          size: 28,
          bold: true,
        },
        null
      ),
      createParagraphWithCenter(
        `технологий о целесообразности государственного финансирования в `,
        {
          size: 28,
          bold: true,
        },
        null
      ),
      createParagraphWithCenter(
        `иностранном государстве предлагаемого лекарственного препарата `,
        {
          size: 28,
          bold: true,
        },
        null
      ),
      createEmptyLine(),
      createAgenciesTable(about, agencies),
    ]
  } catch (err) {
    throw new DocxException(1, err.message)
  }
}
